export interface EnvironmentConfiguration {
    ApiClientId: string
    ApiUrl: string
    ApiScope: string
    PluginClientId: string
    FrontClientId: string
}

export interface ApplicationConfiguration { [key: string]: EnvironmentConfiguration }

const AppConfig: ApplicationConfiguration = {
    development: {
        ApiClientId: 'f4ae1951-7704-4050-9c96-c6851af78973',
        ApiUrl: 'http://localhost:8080',
        ApiScope: 'https://dev.nahal.io/API.Access',
        PluginClientId: 'ef08eefe-f422-4794-9bfe-065b371da72f',
        FrontClientId: 'c10914d2-680a-4320-9009-392b75122e3d'
    },
    staging: {
        ApiClientId: 'f4ae1951-7704-4050-9c96-c6851af78973',
        ApiUrl: 'https://api-staging.nahal.io',
        ApiScope: 'https://dev.nahal.io/API.Access',
        PluginClientId: '14f1c714-8808-403b-abc1-db9d8e31aa2d',
        FrontClientId: 'c10914d2-680a-4320-9009-392b75122e3d'
    },
    production: {
        ApiClientId: '1b655bcf-943c-4938-b71e-d242dd5c4d12',
        ApiUrl: 'https://api.nahal.io',
        ApiScope: 'https://nahal.io/API.Access',
        PluginClientId: 'a5809ebc-46c5-4c76-9897-9946dbf9c937',
        FrontClientId: 'faba69d1-5896-4241-b8c2-f06b921bbd44'
    },
    preprod: {
        ApiClientId: '1b655bcf-943c-4938-b71e-d242dd5c4d12',
        ApiUrl: 'https://api-preprod.nahal.io',
        ApiScope: 'https://nahal.io/API.Access',
        PluginClientId: 'a5809ebc-46c5-4c76-9897-9946dbf9c937',
        FrontClientId: 'faba69d1-5896-4241-b8c2-f06b921bbd44'
    },
}

const getConfig = (): EnvironmentConfiguration => AppConfig[process.env.REACT_APP_ENV ?? 'development'];

export default getConfig;
